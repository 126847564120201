import { exportConfig } from '../configs'
import { makeExportUrl } from '@/utils'

export const buildUrl = (source_id, filters) => {
  const options = {
    only: [
      'id',
      'act_type_id',
      'act_no',
      'act_date',
      'eliminated',
      'elimination_date',
      'stage',
      'subproject_id',
      'week',
      'transfer_week',
      'alleged_fine',
      'fine',
      'contractor_id',
      'subcontractor_id',
      'comment',
      'created_at'
    ],
    include: {
      act_type: {},
      subproject: {},
      contractor: {},
      subcontractor: {}
    }
  }
  const order = [{ created_at: 'desc' }]

  filters.where.unshift({
    field: 'act_type_id',
    op: '!=',
    value: null
  })

  const config = JSON.stringify({
    ...filters,
    ...options,
    order
  })
  return `objectInfo/${source_id}?array=true&config=${config}`
}

export const buildExportUri = (source_id, filters) => {
  const order = [{ created_at: 'desc' }]

  const config = JSON.stringify({
    ...filters,
    order
  })
  const uri = `objectInfo/${source_id}?config=${config}`

  return makeExportUrl(uri, exportConfig)
}
